.about {
  padding: 6rem 3rem 5rem 3rem;
  background: #f8f8f8;
}

.row {
  display: flex;
  justify-content: space-around;
}

.row .column {
  width: 40%;
}

.column .tabs {
  display: flex;
}

.single-tab {
  background: white;
  margin-right: 10px;
  cursor: pointer;
}

.single-tab h2 {
  display: block;
  padding: 10px 16px;
  border-radius: 4px;
  color: #3c3c3c;
  font-size: 14px;
  font-weight: 600;
}

.about-img {
  position: relative;
  background-image: url("../../../public/images/logo.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 350px;
  width: 100%;
  margin-top: 40px;
}

.content {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: none;
}

.active-content {
  display: block;
}

.active-tab {
  background: #242424;
  position: relative;
}

.active-tab::before {
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #242424 transparent transparent transparent;
}

.active-tab h2 {
  color: white;
}

.content h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 1rem;
  color: black;
}

.content p {
  padding-bottom: 1rem;
  font-size: 16px;
  line-height: 1.8;
  font-weight: 400;
  color: #242424;
}

.content h3 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 1rem;
}

/* Skills CSS */
.skills-row {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.skills-column {
  margin-bottom: 30px;
  margin-right: 20px;
}

.progress-wrap h3 {
  font-size: 14px !important;
  color: darkgreen;
  margin-bottom: 10px;
  font-weight: 700;
  text-transform: uppercase;
}

.progress {
  height: 8px;
  background: rgba(0, 0, 0, 0.05);
  overflow: visible;
}

.progress-bar {
  width: 80%;
  height: 8px;
  background: #242424;
  line-height: 1.2;
  position: relative;
  border-radius: 2px;
}

.progress-bar span {
  position: absolute;
  top: -30px;
  right: 0;
  font-size: 16px;
  font-weight: 400;
  color: #242424;
}

.PhotoShop {
  width: 88%;
}

.Javascript {
  width: 85%;
}

.Designer {
  width: 90%;
}

@media screen and (max-width: 768px) {
  .about {
    padding: 2rem 1rem 3rem 1rem;
  }
  .row {
    display: flex;
    flex-direction: column;
  }
  .about-img {
    height: 500px;
  }
  .row .column {
    width: 100%;
  }
  .tabs {
    padding-top: 3rem;
  }
  .skills-row {
    grid-template-columns: 1fr;
  }
}
